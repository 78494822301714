import React from "react";
import "./FooterStyles.css";

// Component that generates a responsive footer section
function Footer() {
    return (
        <div className="footer">
            Code and design by Anthony Pumar
        </div>
    );
}

export default Footer;